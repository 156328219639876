// src/pages/TargetPage.tsx
import React, {useState} from 'react';
import '../Pages.scss';
import {PageProps} from "../Pages";
import PageButtons from "../../components/PageButtons"; // Import your custom page styles

const NodeRedPage: React.FC<PageProps> = (props) => {

    const [useNodeRed, setUseNodeRed] = useState(true);

    const handleToggle = () => setUseNodeRed(!useNodeRed);


    return (
        <div className="section">
            <h2 className="section-title">Node Red</h2>
            <div className="section-description">
                <div className={"section-subtitle"}>What is Node-Red?</div>
                <p>
                    Node-Red is an open-source, flow-based development tool primarily used for wiring together hardware
                    devices, APIs, and online services in industrial and IoT environments. It provides a visual
                    programming interface through a browser-based editor, enabling users to create complex workflows
                    without needing extensive programming skills. With Node-Red, you can easily build and manage data
                    flows by connecting "nodes" (representing devices, APIs, or services) through a simple drag-and-drop
                    interface.
                </p>
                <p>
                    In the context of IIoT (Industrial Internet of Things), Node-Red is particularly powerful because it
                    simplifies the integration of different systems, protocols, and devices. It allows manufacturers to
                    collect data from sensors, machinery, or equipment, transform it in real-time, and route it to
                    databases, dashboards, or other systems for further analysis. Whether you need to automate data
                    collection from industrial devices or integrate with cloud services, Node-Red makes it easy to build
                    and modify these workflows visually.
                </p>
                <div className={"section-subtitle"}>Why did we include Node-Red in our IIoT stack?</div>
                <p>We included Node-Red in our IIoT stack because of its simplicity, flexibility, and ease of use for
                    both technical and non-technical users. Node-Red allows manufacturers and industrial operators to
                    quickly create and modify data flows without writing complex code, enabling faster adoption and more
                    agile development. This is particularly valuable in IIoT environments where new systems, sensors,
                    and protocols often need to be integrated rapidly.</p>

                <p>Node-Red’s drag-and-drop interface provides a low-code solution that reduces development time and
                    empowers operational teams to experiment with different workflows without relying on software
                    developers for every change. It also has a large and active community that has developed a rich
                    library of pre-built nodes for common industrial protocols (e.g., Modbus, OPC UA) and services
                    (e.g., MQTT, databases), making it easy to extend and customize your IIoT solutions.</p>

                <p>For manufacturers looking to monitor and optimize their industrial processes, Node-Red serves as a
                    bridge between data acquisition and automation, allowing you to visualize and act on your data in
                    real-time. By simplifying the way devices and services interact, Node-Red lowers the barrier to
                    implementing effective IIoT solutions.</p>
                <p>
                    For more information see <a href={"https://nodered.org"}>https://nodered.org/</a>
                </p>
            </div>

            <div className="input-title">
                Include Node-Red in our IIoT Stack
            </div>

            <div className="section-description">
            </div>

            <div className="input-content">
                <div className="input-group">
                    <div className="button-column">
                        <input
                            type="checkbox"
                            name="target"
                            value="useDocker"
                            checked={useNodeRed}
                            onChange={handleToggle}
                        />
                    </div>
                    <div className="content-column">
                        <span className="input-title">Include the Mosquitto MQTT broker</span>
                        <span className="input-description">
                       </span>
                    </div>
                </div>
            </div>

            <PageButtons next={props.next} prev={props.prev}/>

        </div>
    );

};

export default NodeRedPage;
