import {PageProps} from "../Pages";
import PageButtons from "../../components/PageButtons";
import React from "react";

const ModbusSerialPage: React.FC<PageProps> = (props) => {
    return (
        <div className="section">
            <h2 className="section-title">Modbus (Serial) PLC</h2>
            <div className="section-description">
                <p>
                    <img src="https://control.com/uploads/thumbnails/PLC-5-Rack_-_featured.jpg"
                         alt="Modicon PLC"
                         width={300}/>
                </p>
                <p>
                    Legacy Modbus PLCs use serial ports to communicate with other devices. Node-Red can
                    support these PLCs using an edge device, such as an embedded computer or Raspberry
                    Pi that has a serial port (RS-232), or an external serial-to-ethernet adapter that
                    can support either RS-232 or RS-485.
                </p>

                <p>
                    The data model is identical to Modbus/TCP:
                    <ul>
                        <li>Coils: Used for digital outputs (read-write)</li>
                        <li>Discrete Inputs: Used for digital inputs (read-only)</li>
                        <li>Input Registers: Used for analog inputs (read-only)</li>
                        <li>Holding Registers: Used for analog outputs (read-write)</li>
                    </ul>
                </p>
            </div>

            <div className="input-content">
                <div className="input-group">
                    <div className="button-column">
                        <input
                            type="checkbox"
                            name="target"
                            value="useDocker"
                            checked={false}
                        />
                    </div>
                    <div className="content-column">
                        <span className="input-title">Include a Modbus Serial example flow</span>
                        <span className="input-description">
                       </span>
                    </div>
                </div>
            </div>
            <PageButtons next={props.next} prev={props.prev}/>

        </div>
    )

}

export default ModbusSerialPage;

