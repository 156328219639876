// src/pages/TargetPage.tsx
import React from 'react';
import '../Pages.scss';
import Spinner from "../../components/Spinner";
import {PageProps} from "../Pages";
import PageButtons from "../../components/PageButtons"; // Import your custom page styles


const GeneratePage: React.FC<PageProps> = (props) => {

    return (
        <div className="section">
            <h2 className="section-title">Generating your IIoT Starter Stack...</h2>

            <div className="section-subtitle"></div>
            <Spinner />

            <PageButtons next={props.next} prev={props.prev}/>

        </div>
    );
};

export default GeneratePage;
