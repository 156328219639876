// src/pages/TargetPage.tsx
import React from 'react';
import '../Pages.scss';
import {PageProps} from "../Pages";
import {PageButtons} from "../../components/PageButtons"; // Import your custom page styles

const StackHome: React.FC<PageProps> = (props) => {
    return (
        <div className="section">
            <h2 className="section-title">IIoT System Builder</h2>

            <div className="section-subtitle"></div>
            <p>Welcome to the GIS IIoT Stack Builder. Answer a few
                questions and we will generate a runnable IIoT stack,
                including data storage, a grafana dashboard, interface
                to your equipment, and more.</p>

            <p>This web application will ask you a series of questions then will
                create a functioning IIoT stack that you can use for test,
                development, and actual deployment. The stack is built from
                open-source, free components and optionally will include
                connectors for common types of manufacturing equipment and
                sensors.
            </p>

            <div className="input-content">
            </div>

            <PageButtons next={props.next} prev={props.prev}/>

        </div>
    );
};

export default StackHome;
