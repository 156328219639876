// src/pages/TargetPage.tsx
import React from 'react';
import '../Pages.scss';
import {PageProps} from "../Pages";
import PageButtons from "../../components/PageButtons"; // Import your custom page styles

const EquipmentSimulatorPage: React.FC<PageProps> = (props) => {
    return (
        <div className="section">
            <h2 className="section-title">Equipment Simulator</h2>
            <div className="section-description">
                <p>
                    <img
                        src="https://upload.wikimedia.org/wikipedia/commons/thumb/5/56/Cartoon_Robot.svg/508px-Cartoon_Robot.svg.png?20140723145632"
                        width={200}
                        alt={"Robot"}/>
                </p>
                <p>
                    The equipment simulator is perfect if you just want to try the stack out
                    and not hook it to any equipment. It is a small Python program that runs
                    in its own container and generates shaped, slightly randomized temperature
                    data that simulates a deep freezer. With the IIoT stack, you can visualize
                    this data and set alarm thresholds using Grafana.
                </p>

                <p>
                    The simulator can be turned off once you have real equipment to connect to the stack.
                </p>

            </div>

            <div className="input-content">
                <div className="input-group">
                    <div className="button-column">
                        <input
                            type="checkbox"
                            name="target"
                            value="useDocker"
                            checked={false}
                        />
                    </div>
                    <div className="content-column">
                        <span className="input-title">Include equipment simulator?</span>
                        <span className="input-description">
                       </span>
                    </div>
                </div>
            </div>
            <PageButtons next={props.next} prev={props.prev}/>

        </div>
    )

};

export default EquipmentSimulatorPage;
