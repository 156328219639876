import {PageProps} from "../Pages";
import PageButtons from "../../components/PageButtons";
import React from "react";

const ModbusTcpPage: React.FC<PageProps> = (props) => {
    return (
        <div className="section">
            <h2 className="section-title">Modbus/TCP PLC</h2>
            <div className="section-description">
                <p>
                    <img src="https://2.bp.blogspot.com/-F1utrQ8omvU/VukOfswAn9I/AAAAAAAAFO8/opKn2YUYpVIMO2TlNHO1_t9WbPYSDDVNg/s1600/Schneider-PLC-M340.jpg"
                         alt="M340 PLC"
                         width={300}/>
                </p>

                <p>
                    Modbus/TCP is a communication protocol used in industrial automation systems. It is based on the
                    Modbus serial protocol and is used to connect programmable logic controllers (PLCs) and other
                    industrial devices. Modbus/TCP is an open standard that is maintained by the Modbus Organization.
                </p>

                <p>
                    Modbus organizes data into <i>registers</i> that are used to store values. The registers are
                    typically organized into four types:
                    <ul>
                        <li>Coils: Used for digital outputs (read-write)</li>
                        <li>Discrete Inputs: Used for digital inputs (read-only)</li>
                        <li>Input Registers: Used for analog inputs (read-only)</li>
                        <li>Holding Registers: Used for analog outputs (read-write)</li>
                    </ul>
                </p>

                <p>
                    Modbus is a "client-server" protocol, where the client device initiates communication
                    with the server devices. The client device sends a request to read or write data to the
                    server device, which then responds with the requested data. In deprecated terms
                    the clients were once referred to as "modbus masters."
                </p>

                <p>
                    Modbus does not support report-by-exception. This means that the client must poll the server
                    to get the latest data. This is typically done with a timer - for example, poll the server every
                    100ms to get the latest data.
                </p>
            </div>

            <div className="input-content">
                <div className="input-group">
                    <div className="button-column">
                        <input
                            type="checkbox"
                            name="target"
                            value="useDocker"
                            checked={false}
                        />
                    </div>
                    <div className="content-column">
                        <span className="input-title">Include a Modbus/TCP example flow</span>
                        <span className="input-description">
                       </span>
                    </div>
                </div>
            </div>
            <PageButtons next={props.next} prev={props.prev}/>

        </div>
    )

}

export default ModbusTcpPage;

