// src/pages/AppHome.tsx
import React from "react";
import { Link } from "react-router-dom";
import { PageGroups } from "../pages/Pages";
import "./AppHome.scss";


const AppHome: React.FC = () => {
    return (
        <div className="app-home">
            <h1>Welcome to the IIoT Toolkit</h1>
            <p>Please choose a section to get started:</p>
            <ul>
                {PageGroups.map(group => (
                    <li key={group.title}>
                        <Link to={group.pages[0].path}>{group.title}</Link>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default AppHome;
