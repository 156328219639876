// src/pages/DatabasePage.tsx
import React, {useState} from 'react';
import {PageProps} from "../Pages";
import PageButtons from "../../components/PageButtons";

const DatabasePage: React.FC<PageProps> = (props) => {
    const [useTimescale, setUseTimescale] = useState(true);

    const handleToggle = () => setUseTimescale(!useTimescale);

    return (
        <div className="section">
            <h2 className="section-title">Database</h2>
            <div className="section-description">
                <div className={"section-subtitle"}>What is Node-Red?</div>
                <p>
                    TimescaleDB is an open-source, time-series database optimized for fast ingest and complex queries.
                    It is built on top of PostgreSQL, extending the relational database with time-series data
                    capabilities. TimescaleDB is designed to scale horizontally across multiple nodes, making it
                    suitable for high-performance applications that require real-time analytics and monitoring. With
                    TimescaleDB, you can store and analyze time-series data from IoT devices, industrial equipment, and
                    other time-stamped sources efficiently.
                </p>
                <p>
                    For more information see <a href={"https://www.timescale.com/"}>https://www.timescale.com/</a>
                </p>
            </div>

            <div className="input-title">
                Include Node-Red in our IIoT Stack
            </div>

            <div className="section-description">
            </div>

            <div className="input-content">
                <div className="input-group">
                    <div className="button-column">
                        <input
                            type="checkbox"
                            name="target"
                            value="useDocker"
                            checked={useTimescale}
                            onChange={handleToggle}
                        />
                    </div>
                    <div className="content-column">
                        <span className="input-title">Include the TimescaleDB time series database</span>
                        <span className="input-description">
                       </span>
                    </div>
                </div>
            </div>

            <PageButtons next={props.next} prev={props.prev}/>

        </div>
    );

};

export default DatabasePage;
