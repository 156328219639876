import {PageProps} from "../Pages";
import PageButtons from "../../components/PageButtons";
import React from "react";


const EthernetIpPage: React.FC<PageProps> = (props) => {
    return (
        <div className="section">
            <h2 className="section-title">Ethernet IP PLC</h2>
            <div className="section-description">
                <p>
                    <img src="https://www.asteamtechnologies.com/wp-content/uploads/2018/10/2080-LC50-24AWB-Allen-Bradley.jpg"
                         alt="Allen Bradley PLC"
                         width={300}/>
                </p>
                <p>
                    EthernetIP is a communication protocol used in industrial automation systems. It is based on the
                    Common Industrial Protocol (CIP) and is used to connect programmable logic controllers (PLCs) and
                    other industrial devices. EthernetIP is an open standard that is maintained by the Open DeviceNet
                    Vendor Association (ODVA).
                </p>

                <p>
                    EthernetIP is most commonly used by Allen-Bradley PLCs that have an ethernet network communications
                    port. Other manufacturers support CIP as well. Depending on the PLC, individual points (inputs,
                    outputs, and registers) will either be named with tags or addresses. The tags are used to reference
                    the points in the PLC program and are used in the PLC program to reference the points using
                    human-readable names. If the PLC only supports addresses, the addresses are used to reference the
                    points in the PLC program.
                </p>
                <p>
                    Allen-Bradley PLCs organize data into <i>pages</i> of registers organized by type.

                    <ul>
                        <li>Nx:yyyy - integer registers (analog inputs and outputs may be mapped here</li>
                        <li>Ix:yyyy = Input registers (digital)</li>
                        <li>Ox:yyyy = Output registers (digital)</li>
                        <li>Bx:yyyy = Bit registers</li>
                        <li>Sx:yyyy = String registers</li>
                        <li>Fx:yyyy = Floating point registers</li>
                        <li>Tx:yyyy = Timer registers</li>
                        <li>Cx:yyyy = Counter registers (which may include position encoders)</li>
                        <li>STx:yyyy = Status registers</li>
                    </ul>
                    where <i>x</i> represents the page number and <i>yyyy</i> represents the register number.
                </p>
            </div>

            <div className="input-content">
                <div className="input-group">
                    <div className="button-column">
                        <input
                            type="checkbox"
                            name="target"
                            value="useDocker"
                            checked={false}
                        />
                    </div>
                    <div className="content-column">
                        <span className="input-title">Include an Ethernet IP example flow</span>
                        <span className="input-description">
                       </span>
                    </div>
                </div>
            </div>
            <PageButtons next={props.next} prev={props.prev}/>

        </div>
    );
}

export default EthernetIpPage;

