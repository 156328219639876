// src/pages/TargetPage.tsx
import React from 'react';
import '../Pages.scss';
import {PageProps} from "../Pages";
import PageButtons from "../../components/PageButtons"; // Import your custom page styles
import {MqttSelection} from "../../model/Document";


const NodeRedPage: React.FC<PageProps> = (props) => {

    // const [selectedMqtt, setSelectedMqtt] = useState<MqttChoice>(MqttChoice.MOSQUITTO);
    //
    const handleTargetChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        props.updateDocument({mqtt: event.target.value as MqttSelection});
    };


    return (
        <div className="section">
            <h2 className="section-title">MQTT Broker</h2>
            <div className="section-description">
                <div className={"section-subtitle"}>What is MQTT?</div>
                <p>
                    MQTT (Message Queuing Telemetry Transport) is a lightweight, publish-subscribe messaging protocol
                    commonly used in the Internet of Things (IoT) and Industrial Internet of Things (IIoT) ecosystems.
                    It is designed to enable devices, sensors, and applications to communicate in real time with minimal
                    bandwidth and energy consumption. One of MQTT’s key features is its efficiency, making it ideal for
                    environments where network bandwidth is limited, such as industrial settings, remote monitoring, or
                    environments with many interconnected devices.
                </p>
                <p>
                    Overall, MQTT is a foundational protocol in IIoT stacks because it provides the reliable, real-time
                    data exchange needed to monitor, optimize, and automate industrial processes efficiently.
                </p>

                <div className={"section-subtitle"}>Why did we include MQTT in our IIoT stack?</div>
                <p>In an IIoT stack, MQTT is crucial because of its reliability, scalability, and ease of deployment.
                    In our standard stack, many types of intelligent field equipment (like modern PLCs)
                    provide the monitoring and control in real time using MQTT. If we also provide you with
                    an edge device or equipment interface, we use MQTT in part to provide "data diode" IT/OT
                    separation. This means that the data flows from the field equipment to the cloud, but
                    the cloud cannot send data back to the field equipment.</p>

                <p>MQTT’s publish-subscribe model allows devices to send data (publish) to a central broker, where
                    interested devices or applications can subscribe to receive that data. This decoupled communication
                    model reduces network traffic and makes it easy to scale as the number of devices grows.
                    Furthermore, MQTT supports quality of service (QoS) levels, ensuring that messages are delivered
                    reliably even in unstable network conditions, which is essential for critical industrial operations.
                    Its lightweight nature also allows it to be implemented on low-power devices and networks, making it
                    ideal for IIoT applications that span large geographical areas, such as remote asset management,
                    predictive maintenance, and energy monitoring.</p>
                <p>
                    For more information see <a href={"https://mqtt.org/"}>https://mqtt.org/</a>
                </p>
            </div>

            <div className="input-title">
                Include Node-Red in our IIoT Stack
            </div>

            <div className="section-description">
            </div>

            <div className="input-content">
                <div className="input-group">
                    <div className="button-column">
                        <input
                            type="radio"
                            name="target"
                            checked={props.doc.mqtt === MqttSelection.MOSQUITTO}
                            value={MqttSelection.MOSQUITTO}
                            onChange={handleTargetChange}
                        />
                    </div>
                    <div className="content-column">
                        <span className="input-title">Use Mosquitto broker</span>
                        <span className="input-description">
                            Mosquitto is a simple, free MQTT broker often used for
                            development and small systems.  It is not as fully-featured
                            as some options but its advantage is that it is light-weight
                            and easy to configure.  For more information about mosquitto
                            see <a href={"https://mosquitto.org/"}>https://mosquitto.org/</a>
                       </span>
                    </div>
                </div>

                <div className="input-group">
                    <div className="button-column">
                        <input
                            type="radio"
                            name="target"
                            checked={props.doc.mqtt === MqttSelection.SELF}
                            value={MqttSelection.SELF}
                            onChange={handleTargetChange}
                        />
                    </div>
                    <div className="content-column">
                        <span className="input-title">I will provide my own MQTT broker</span>
                        <span className="input-description">
                            I will provide my own MQTT broker.  This means I will manually
                            connect it to Node-Red later (it's not difficult).
                       </span>
                    </div>
                </div>

                <div className="input-group">
                    <div className="button-column">
                        <input
                            type="radio"
                            name="target"
                            checked={props.doc.mqtt === MqttSelection.NONE}
                            value={MqttSelection.NONE}
                            onChange={handleTargetChange}
                        />
                    </div>
                    <div className="content-column">
                        <span className="input-title">No broker</span>
                        <span className="input-description">
                            I don't need an MQTT broker.  My devices will connect directly to Node-Red
                            using the network (Ethernet) or serial interface of the server that runs this IIoT stack.
                       </span>
                    </div>
                </div>
            </div>

            <PageButtons next={props.next} prev={props.prev}/>

        </div>
    );


};

export default NodeRedPage;
