// src/pages/TargetPage.tsx
import React from 'react';
import '../Pages.scss';
import {PageProps} from "../Pages";
import PageButtons from "../../components/PageButtons"; // Import your custom page styles

const EquipmentHome: React.FC<PageProps> = (props) => {
    return (
        <div className="section">
            <h2 className="section-title">Equipment</h2>
            <div className="section-description">
                <p>
                    The following pages will add Node Red pages to link to example equipment.  These
                    pages will be linked into your edge device Node-Red configuration.

                </p>
            </div>


            <PageButtons next={props.next} prev={props.prev}/>

        </div>
    );

};

export default EquipmentHome;
