import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import './NavigationMenu.scss';
import { PageGroups } from '../pages/Pages';

const NavigationMenu: React.FC = () => {
    const location = useLocation(); // Get the current location

    // Initialize the collapsed state for each group
    const [collapsedGroups, setCollapsedGroups] = useState<{ [key: string]: boolean }>({});

    // Toggle the collapsed state of a group
    const toggleGroup = (groupTitle: string) => {
        setCollapsedGroups((prevState) => ({
            ...prevState,
            [groupTitle]: !prevState[groupTitle], // Toggle the group's collapsed state
        }));
    };

    // Expand the correct group based on the current location
    useEffect(() => {
        PageGroups.forEach((group) => {
            const isInGroup = group.pages.some(page => location.pathname.startsWith(page.path));
            // If the current path is in this group, expand it and collapse others
            if (isInGroup) {
                setCollapsedGroups((prevState) => ({
                    ...prevState,
                    [group.title]: false // Expand the current group
                }));
            } else {
                setCollapsedGroups((prevState) => ({
                    ...prevState,
                    [group.title]: true // Collapse other groups
                }));
            }
        });
    }, [location.pathname]);

    return (
        <div className="navigation-menu">
            <ul>
                {PageGroups.map((group) => (
                    <React.Fragment key={group.title}>
                        <li
                            className="group-title"
                            onClick={() => toggleGroup(group.title)} // Click to toggle
                        >
                            {group.title} {collapsedGroups[group.title] ? "▶" : "▼"}
                        </li>
                        {!collapsedGroups[group.title] && (
                            <ul className="group-pages"> {/* Ensure group-pages class is applied */}
                                {group.pages.map((page) => (
                                    <li
                                        key={page.path}
                                        className={location.pathname === page.path ? "active" : ""}
                                    >
                                        <Link to={page.path}>{page.title}</Link>
                                    </li>
                                ))}
                            </ul>
                        )}
                    </React.Fragment>
                ))}
            </ul>
        </div>
    );
};

export default NavigationMenu;
