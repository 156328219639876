// src/pages/TargetPage.tsx
import React, {useState} from 'react';
import '../Pages.scss';
import {PageProps} from "../Pages";
import PageButtons from "../../components/PageButtons"; // Import your custom page styles

const GrafanaPage: React.FC<PageProps> = (props) => {


    const [useGrafana, setUseGrafana] = useState(true);

    const handleToggle = () => setUseGrafana(!useGrafana);


    return (
        <div className="section">
            <h2 className="section-title">Grafana</h2>
            <div className="section-description">
                <p>
                    Grafana is an open-source platform used for monitoring, visualizing, and analyzing data.
                    It is widely used for building real-time dashboards and graphs from various data sources,
                    such as databases, cloud services, and IoT devices.
                </p>
                <p>
                    <div className="section-subtitle">Key Features of Grafana</div>
                    <ul>
                        <li>Dashboards and Visualizations: Grafana allows users to create customizable, interactive
                            dashboards. It offers various visualization types such as graphs, heatmaps, tables, and more
                            to represent your data visually.
                        </li>
                        <li>Data Sources: Grafana supports many data sources, including databases like PostgreSQL,
                            MySQL, and time-series databases like InfluxDB and Prometheus. It can also integrate with
                            cloud services and IoT platforms.
                        </li>
                        <li>Real-Time Monitoring: Grafana is commonly used for monitoring infrastructure, applications,
                            and systems in real-time, providing immediate insights into the status of services.
                        </li>
                        <li>Alerts: Grafana supports alerting, meaning users can set up notifications (e.g., via email
                            or Slack) based on predefined conditions within the data.
                        </li>
                        <li>Plugins and Extensibility: Grafana has a rich ecosystem of plugins, allowing users to add
                            custom data sources, visualization types, and more.
                        </li>
                    </ul>
                </p>

                <p>
                    <div className="section-subtitle">Why did we include Grafana in our IIoT Stack?</div>
                    <ul>
                        <li>Ease of Use: Grafana’s user-friendly interface allows users to quickly create dashboards and
                            visualize data without extensive coding.
                        </li>
                        <li>Customization: The flexibility to create complex, tailored dashboards makes it a preferred
                            tool for engineers, data scientists, and IT administrators.
                        </li>
                        <li>Community and Open-Source: Grafana is free to use, with a large community contributing to
                            its plugins and extensions.
                        </li>
                    </ul>
                </p>
                <p>
                    For more information see <a href={"https://grafana.com"}>https://grafana.com/</a>
                </p>
            </div>

            <div className="input-title">
                Include Grafana in our IIoT Stack
            </div>

            <div className="section-description">
            </div>

            <div className="input-content">
                <div className="input-group">
                    <div className="button-column">
                        <input
                            type="checkbox"
                            name="target"
                            value="useDocker"
                            checked={useGrafana}
                            onChange={handleToggle}
                        />
                    </div>
                    <div className="content-column">
                        <span className="input-title">Include grafana</span>
                        <span className="input-description">

                       </span>
                    </div>
                </div>
            </div>

            <PageButtons next={props.next} prev={props.prev}/>

        </div>
    );


};

export default GrafanaPage;
