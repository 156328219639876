// src/pages/TargetPage.tsx
import React, {useState} from 'react';
import '../Pages.scss';
import {PageProps} from "../Pages";
import PageButtons from "../../components/PageButtons"; // Import your custom page styles

const TargetPage: React.FC<PageProps> = (props) => {
    const [selectedTarget, setSelectedTarget] = useState<string>('useDocker');

    const handleTargetChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSelectedTarget(event.target.value);
    };

    return (
        <div className="section">
            <h2 className="section-title">Step 2: Select Target</h2>

            <div className="section-description">
                <p>
                    The target system selection determines how the stack will be run. For trial deployments,
                    you can choose to create a containerized stack using either
                    <a href={"https://www.docker.com/"} target="_blank" rel="noreferrer">Docker</a> or
                    <a href={"https://podman.io/"} target="_blank" rel="noreferrer">Podman</a>. These
                    installations are built as a <a
                    href="https://docs.docker.com/reference/compose-file/build/">compose</a> stack, where we give you a
                    set of files
                    that define the stack components and how they are connected together. This same
                    stack can be used on an on-premises server as the system moves out of the trial phase.
                </p>
                <p>
                    This same stack can also be deployed to a linux instance on
                    <a href="aws.amazon.com" target="_blank" rel="noreferrer">Amazon Web Services</a> using
                    or another
                </p>
            </div>

            <div className="input-content">
                <div className="input-group">
                    <div className="button-column">
                        <input
                            type="radio"
                            name="target"
                            value="useDocker"
                            checked={selectedTarget === 'useDocker'}
                            onChange={handleTargetChange}
                        />
                    </div>
                    <div className="content-column">
                        <span className="input-title">Docker/Podman Compose</span>
                        <span className="input-description">
                            I want an IoT stack that can run on a local Linux server or desktop, or a Windows server
                            or desktop using the Windows Subsystem for Linux along with Podman Desktop or Docker
                            Desktop.
                       </span>
                    </div>
                </div>

                <div className="input-group">
                    <div className="button-column">
                        <input
                            type="radio"
                            name="target"
                            value="useAws"
                            checked={selectedTarget === 'useAws'}
                            onChange={handleTargetChange}
                        />
                    </div>
                    <div className="content-column">
                        <span className="input-title">AWS EC2</span>
                        <span className="input-description">
              Build an IoT stack optimized to run on the Amazon Elastic Container Service (ECS) using the EC2 deployment strategy. This requires a virtual machine running 24x7 with a fixed monthly fee, which depends on the machine’s specifications.
            </span>
                    </div>
                </div>

                <div className="input-group">
                    <div className="button-column">
                        <input
                            type="radio"
                            name="target"
                            value="useFargate"
                            checked={selectedTarget === 'useFargate'}
                            onChange={handleTargetChange}
                        />
                    </div>
                    <div className="content-column">
                        <span className="input-title">AWS Fargate</span>
                        <span className="input-description">
                            Build an IoT stack optimized to run on AWS Fargate, which costs more but may require less system maintenance. This deployment strategy eliminates the need for managing the underlying virtual machine.
                        </span>
                    </div>
                </div>
            </div>

            <PageButtons next={props.next} prev={props.prev}/>

        </div>
    );
};

export default TargetPage;
