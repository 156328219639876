// src/pages/TargetPage.tsx
import React from 'react';
import '../Pages.scss';
import {PageProps} from "../Pages";
import PageButtons from "../../components/PageButtons"; // Import your custom page styles

const VpnPage: React.FC<PageProps> = (props) => {
    return (
        <div className="section">
            <h2 className="section-title">Remote Support</h2>
            <div className="section-description">
                <p>
                    <img
                        src="https://upload.wikimedia.org/wikipedia/commons/thumb/5/51/Antu_network-vpn.svg/512px-Antu_network-vpn.svg.png?20160706123748"
                        width={200}
                        alt={"VPN"}/>
                </p>
                <p>
                    The IIoT stack can optionally include a Remote Support tunnel for helping
                    diagnose and troubleshoot your configuration.  This works by setting up a
                    secure, encrypted connection from the IIoT stack back to the remote support
                    team.  This allows the team to access the stack and help diagnose certain
                    issues without being on site.
                </p>

                <p>
                    This access can be disabled later by stopping or removing the associated
                    container.
                </p>

            </div>

            <div className="input-content">
                <div className="input-group">
                    <div className="button-column">
                        <input
                            type="checkbox"
                            name="target"
                            value="useDocker"
                            checked={false}
                        />
                    </div>
                    <div className="content-column">
                        <span className="input-title">Enable the Remote Support VPN?</span>
                        <span className="input-description">
                       </span>
                    </div>
                </div>
            </div>
            <PageButtons next={props.next} prev={props.prev}/>

        </div>
    )

};

export default VpnPage;
