// src/pages/TargetPage.tsx
import React from 'react';
import '../Pages.scss';
import {PageProps} from "../Pages";
import PageButtons from "../../components/PageButtons"; // Import your custom page styles

const ShellyPage: React.FC<PageProps> = (props) => {

    return (
        <div className="section">
            <h2 className="section-title">Shelly Device</h2>
            <div className="section-description">
                <p>
                    <img src="https://www.shelly.com/_Resources/Persistent/8/5/2/f/852f53cd8a3db4aa15343b2cb0d3e3ea7604d4d5/Shelly_Plusi4_x1_Gen3_PDP-625x625.png"
                         width={200}
                         alt={"Shelly Device"}/>
                </p>
                <p>
                    Shelly devices (<a href="https://www.shelly.com">https://www.shelly.com</a>) are a series of smart
                    home automation devices that are designed to be easy to install and use. They are compatible with
                    the MQTT protocol, which makes them compatible with your IIoT stack if it includes an
                    MQTT broker like Mosquitto. Shelly devices are a low-cost way to monitor low voltage digital
                    inputs, and temperatures using low-cost Dallas one-wire temperature sensors (which can
                    measure -1&deg;C to +85&deg;C). These are especially useful for low-cost refrigerator
                    and freezer monitoring.
                </p>

                <p>
                    We typically use Shelly version 2 devices that are capable of running
                    <a href="https://shelly-api-docs.shelly.cloud/gen2/Scripts/Tutorial/">Shelly Scripts</a>,
                    a subset of JavaScript. These devices natively speak MQTT, but the scripting
                    ability allows us to add custom logic to the devices, mainly to make them send
                    MQTT messages in a format of our choosing at the rate we desire.
                </p>


                <p>
                    Here is an example Shelly script that sends the temperature of a Shelly device to an MQTT broker.
                    <i>result.id</i> is the Shelly device ID, and <i>topic_prefix</i> is the MQTT topic prefix.
                    This function will be called periodically by invoking it with a call to <i>Timer.set()</i>:
                </p>
                <p>
                    <div className="code">
                        function update(n) &#123;<br/>
                        &nbsp;&nbsp;Shelly.call("Temperature.GetStatus", &#123; id: n &#125;, function (result,
                        error_code, error_message) &#123;<br/>
                        &nbsp;&nbsp;&nbsp;&nbsp;if (topic_prefix !== null) &#123;<br/>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;if(result) &#123;<br/>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;let topic = topic_prefix +
                        "/status/temperature:" + result.id;<br/>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;print(result);<br/>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;MQTT.publish(topic, JSON.stringify(result), 0,
                        false);<br/>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#125;<br/>
                        &nbsp;&nbsp;&nbsp;&nbsp;&#125;<br/>
                        &nbsp;&nbsp;&#125;);<br/>
                        &#125;
                    </div>
                </p>

                <p>
                    Enabling this option will create a Node-Red flow to receive these messages from an MQTT
                    broker and store them in your time-series database. A complete Shelly script will also
                    be included in your generated archive. (Future versions of this starter kit will upload
                    the script to the Shelly device, but for now you will have to copy and paste it manually).
                </p>
            </div>

            <div className="input-content">
                <div className="input-group">
                    <div className="button-column">
                        <input
                            type="checkbox"
                            name="target"
                            value="useDocker"
                            checked={false}
                        />
                    </div>
                    <div className="content-column">
                        <span className="input-title">Include a Shelly example flow</span>
                        <span className="input-description">
                       </span>
                    </div>
                </div>
            </div>
            <PageButtons next={props.next} prev={props.prev}/>

        </div>
    )

};

export default ShellyPage;
