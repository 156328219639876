import React from "react";
import { Link } from "react-router-dom";
import "./PageButtons.scss"


export interface PageButtonProps {
    next?: string;
    prev?: string;
}

export const PageButtons: React.FC<PageButtonProps> = ({ next, prev }) => {
    return (
        <div className="button-bar">
            {prev && (
                <div className="button-link">
                    <Link className="link-style" to={prev}>
                        Previous
                    </Link>
                </div>
            )}
            {next && (
                <div className="button-link">
                    <Link className="link-style" to={next}>
                        Next
                    </Link>
                </div>
            )}
        </div>
    );
};

export default PageButtons;
