import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import NavigationMenu from './components/NavigationMenu';
import './App.scss';
import { PageGroups } from "./pages/Pages";
import { IotStackDocument, MqttSelection } from "./model/Document";
import AppHome from "./pages/AppHome";

const App: React.FC = () => {

    const [iotStackDocument, setIotStackDocument] = useState<IotStackDocument>({
        generateIotStack: true,
        mqtt: MqttSelection.MOSQUITTO
    });

    const updateDocument = (newData: Partial<IotStackDocument>) => {
        setIotStackDocument((prevDoc) => ({
            ...prevDoc,
            ...newData, // Merge the new data into the document
        }));
    };

    // Step 2: Flatten all the pages across groups into a single list
    const allPages = PageGroups.flatMap(group => group.pages);

    return (
        <Router>
            <div className="app-container">
                <NavigationMenu />
                <div className="content">
                    <Routes>
                        <Route key="apphome" path="/" element={<AppHome />} />

                        {/* Map over allPages to generate Routes for each page */}
                        {allPages.map((page, index) => {
                            const prev = allPages[index - 1]?.path || undefined;
                            const next = allPages[index + 1]?.path || undefined;

                            return (
                                <Route
                                    key={page.path}
                                    path={page.path}
                                    element={
                                        <page.component
                                            next={next}
                                            prev={prev}
                                            doc={iotStackDocument}
                                            updateDocument={updateDocument}
                                        />
                                    }
                                />
                            );
                        })}
                    </Routes>
                </div>
            </div>
        </Router>
    );
};

export default App;
