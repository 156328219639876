import MqttPage from "./iotstack/MqttPage";
import GrafanaPage from "./iotstack/GrafanaPage";
import NodeRedPage from "./iotstack/NodeRedPage";
import SystemInfoPage from "./iotstack/SystemInfo";
import TargetPage from "./iotstack/Target";
import DatabasePage from "./iotstack/DatabasePage";
import GeneratePage from "./iotstack/GeneratePage";
import React from "react";
import StackHome from "./iotstack/StackHome";
import {IotStackDocument} from "../model/Document";
import EquipmentHome from "./equipment/EquipmentHome";
import ShellyPage from "./equipment/ShellyPage";
import ModbusTcpPage from "./equipment/ModbusTcpPage";
import EthernetIpPage from "./equipment/EthernetIpPage";
import ModbusSerialPage from "./equipment/ModbusSerial";
import EquipmentSimulatorPage from "./equipment/Simulation";
import VpnPage from "./equipment/VPN";

export interface PageProps {
    next?: string
    prev?: string
    doc: IotStackDocument
    updateDocument: (newData: Partial<IotStackDocument>) => void; // Pass this function down
}


export interface PageInfo {
    path: string
    title: string
    component: React.FC<PageProps>
    next?: string,
    prev?: string
}

export interface PageGroup {
    title: string
    pages: PageInfo[]
}

export const PageGroups: PageGroup[] = [
    {
        title: "IIoT Stack",
        pages: [
            { path: "/iotstack", title: "Start", component: StackHome },
            { path: "/iotstack/sysinfo", title: "System Info", component: SystemInfoPage },
            { path: "/iotstack/target", title: "Target", component: TargetPage },
            { path: "/iotstack/database", title: "Database", component: DatabasePage },
            { path: "/iotstack/mqtt", title: "MQTT", component: MqttPage },
            { path: "/iotstack/grafana", title: "Grafana", component: GrafanaPage },
            { path: "/iotstack/nodered", title: "Node Red", component: NodeRedPage },
            { path: "/iotstack/vpn", title: "Remote Support", component: VpnPage }
        ]
    },
    {
        title: "Equipment",
        pages: [
            { path: "/equipment", title: "Start", component: EquipmentHome },
            { path: "/equipment/simulator", title: "Simulator", component: EquipmentSimulatorPage},
            { path: "/equipment/modbustcp", title: "Modbus TCP PLC", component: ModbusTcpPage },
            { path: "/equipment/modbusserial", title: "Modbus Serial PLC", component: ModbusSerialPage },
            { path: "/equipment/ethernetip", title: "EthernetIP PLC", component: EthernetIpPage },
            { path: "/equipment/shelly", title: "Shelly Device", component: ShellyPage }
        ]
    },
    {
        title: "Finish Up",
        pages: [
            { path: "/finish", title: "Generate", component: GeneratePage }
        ]
    }
];
